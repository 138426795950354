import * as React from 'react';

const About = () => {
	return (
		<section>
			<h1 role="title">ABOUT</h1>
		</section>
	)
}

export default About;